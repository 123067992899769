export default {
  en: 'English',
  fr: 'Français',
  de: 'Deutsch',
  ja: '日本語',
  pt: 'Português',
  'es-mx': 'Español - Latinoamérica',
  es: 'Español',
  da: 'Dansk',
  cs: 'Čeština',
  fi: 'Suomi',
  it: 'Italiano',
  nl: 'Nederlands',
  'no-no': 'Norsk – Norge',
  noNO: 'Norsk – Norge',
  pl: 'Polski',
  sv: 'Svenska',
  ko: '한국어',
  el: 'Eλληνικά',
  'zh-cn': '中文 - 简体',
  'zh-hk': '中文 - 香港',
  th: 'ไทย',
  id: 'Bahasa Indonesia',
  ru: 'Pусский',
  ro: 'Română',
  vi: 'Tiếng Việt',
  hr: 'Hrvatski',
  hu: 'Magyar',
  af: 'Afrikaans',
  'ar-eg': 'اَلْعَرَبِيَّةُ',
  bg: 'български',
  'ca-es': 'Català',
  sl: 'Slovenščina',
  tr: 'Türkçe',
  uk: 'українська',
  bn: 'বাংলা',
  'en-gb': 'English - United Kingdom',
  'fr-ca': 'Français - Canada',
  'he-il': 'עברית',
  lt: 'Lietuvių',
  no: 'Norsk',
  'pt-br': 'Português - Brasil',
  'pt-pt': 'Português - Portugal',
  sk: 'Slovenčina',
  'zh-tw': '中文 - 繁體',
  zh: '中文 - 繁體',
  // for user languages, zh-tw is our only supported 'zh' language. If we add another zh variant to our user locales, we should update this
  ms: 'Bahasa Melayu',
  tl: 'Filipino'
};