// @ts-expect-error not converted yet
import { reportError } from 'ContentUtils/helpers/sentry';
import { fetchPublicPlayer } from '../utils/api';
import { FETCH_VIDEO_SUCCESS, FETCH_VIDEO_FAILED, FETCH_VIDEO_ATTEMPTED, SET_PLAYER_ERROR, CONFIRMED_VISIBLE } from './ActionTypes';
import { loadCaptions } from './captions';
import createUsageTracker from '../utils/tracker';
export const loadVideo = (videoId, params) => dispatch => {
  const parentDomain = params.parentOrigin ? params.parentOrigin.replace(/^https?:\/\//, '') : null;
  const usageTracker = createUsageTracker();
  dispatch({
    type: FETCH_VIDEO_ATTEMPTED
  });
  fetchPublicPlayer(videoId, {
    parentDomain,
    pageId: params.pageId
  }).then(async player => {
    dispatch(Object.assign({
      type: FETCH_VIDEO_SUCCESS
    }, player));
    if (player.video.subtitleLanguageCodes.length) {
      await dispatch(loadCaptions(videoId));
    }
    usageTracker.track('videoLoaded', {
      duration: typeof window.performance.now === 'function' ? window.performance.now() : undefined,
      success: true,
      statusCode: 200,
      viaQuickFetch: Boolean(window._usedQuickFetch)
    });
  }).catch(error => {
    console.error('Video fetch failed', error);
    reportError(error, {
      errorId: FETCH_VIDEO_FAILED
    });
    usageTracker.track('videoLoaded', {
      success: false,
      statusCode: error.status
    });
    dispatch({
      type: FETCH_VIDEO_FAILED,
      videoFetchError: error
    });
  });
};
export const setAppError = appError => ({
  type: SET_PLAYER_ERROR,
  appError
});
export const confirmVisible = () => ({
  type: CONFIRMED_VISIBLE
});