import I18n from 'I18n';
import videojs from 'videojs-mux-kit';
import PortalIdParser from 'PortalIdParser';
import LanguageNames from 'ui-addon-i18n/constants/LanguageNames';
import { getMuxEnvKey } from './env';
import { setupTranslations } from './I18n';
import './errorPlugin';
import './playbackOptions';
export const HLS_LAZY_LOAD_DELAY = 8000;
export function getHlsTech(player) {
  const tech = player.tech('Html5');
  if (tech && tech.hlsjs) {
    return tech.hlsjs;
  }
  return null;
}
const CONTROL_BAR_CHILDREN = ['PlayToggle', 'ProgressControl', 'RemainingTimeDisplay', 'VolumePanel', 'CaptionsButton', 'PlaybackPopover', 'FullscreenToggle'];
const getBasePlayerConfig = (video, options, trackingProps) => {
  const controlBarChildren = Array.from(CONTROL_BAR_CHILDREN);
  const config = {
    html5: {
      hls: {
        // debug: true,
        // we call `hls.startLoad(-1)` explicitly so lazy loading is possible
        autoStartLoad: false,
        // max MB of buffer (default 60), see https://github.com/video-dev/hls.js/blob/master/docs/API.md
        maxBufferSize: 20,
        // max seconds of buffering (default 30s)
        maxBufferLength: 15
      }
    },
    plugins: {
      mux: {
        disableCookies: true,
        data: {
          env_key: getMuxEnvKey(),
          player_init_time: Date.now(),
          player_name: trackingProps.renderContext || 'video-player-ui',
          player_autoplay: Boolean(options.autoplay),
          player_preload: false,
          video_id: video.videoId,
          video_title: video.title,
          video_producer: PortalIdParser.get(),
          sub_property_id: PortalIdParser.get(),
          page_url: trackingProps.pageUrl
        }
      }
    },
    controlBar: {
      children: controlBarChildren,
      AudioTrackButton: true,
      VolumePanel: {
        inline: false
      }
    },
    fill: true,
    timelineHoverPreviews: true,
    userActions: {
      doubleClick: false
    }
  };

  // Mux Data works for raw FM file urls as well in which case `video_cdn` will be missing, this allows for easy filtering
  if (!video.primaryPlaybackId) {
    config.plugins.mux.data.video_cdn = 'hs-files';
  }
  return config;
};
export default function createPlayer(idOrElement, options, video, trackingProps) {
  const posterUrl = options.posterUrl || video.posterUrl;
  const source = video.primaryPlaybackId ? {
    type: 'video/mux',
    src: video.primaryPlaybackId
  } : {
    src: video.fileUrl
  };
  if (options.locale) {
    videojs.addLanguage(options.locale, setupTranslations());
  }
  const player = videojs(idOrElement, Object.assign({}, getBasePlayerConfig(video, options, trackingProps), {
    sources: [source],
    poster: options.hidePosterImage ? undefined : posterUrl,
    controls: !options.hiddenControls,
    loop: options.loop,
    muted: options.muted,
    language: options.locale
  }));
  if (options.playbackRate && options.playbackRate !== 1) {
    player.ready(() => player.playbackRate(options.playbackRate));
  }
  return player;
}
export function translateTrackLanguageCode(captionLang) {
  if (
  // @ts-expect-error lookup util not typed (we cannot use text() because it returns a missing translation placeholder
  I18n.lookup(`SharedI18nStrings.languageNames.${captionLang}`)) {
    return I18n.text(`SharedI18nStrings.languageNames.${captionLang}`);
  } else if (captionLang in LanguageNames) {
    return LanguageNames[captionLang];
  }
  return captionLang;
}