import PortalIdParser from 'PortalIdParser';
import quickFetch from 'quick-fetch';
import { getFullUrl } from 'hubspot-url-utils';
import http from 'hub-http/clients/noAuthApiClient';
// @ts-expect-error not yet converted
import { reportError } from 'ContentUtils/helpers/sentry';
import { isAuthedAppPreview } from 'video-embed/utils/env';
function fetchPlayer(id, params = {}) {
  const query = Object.assign({
    portalId: PortalIdParser.get()
  }, params);
  const path = isAuthedAppPreview() ? `video/v1/public/${id}/preview-player` : `video/v1/public/${id}/player`;
  return http.get(path, {
    query,
    withCredentials: false
  });
}
export function fetchPublicPlayer(id, params = {}) {
  const earlyRequest = quickFetch.getRequestStateByName('videoPlayer');
  if (earlyRequest) {
    return new Promise((resolve, reject) => {
      earlyRequest.whenFinished(resp => {
        // we can safely assume the domain is hosted on the CMS via request over customer domain working
        if (!resp.domainInfo) {
          resp.domainInfo = {
            isCms: true
          };
        }
        window._usedQuickFetch = true;
        quickFetch.removeEarlyRequest('videoPlayer');
        resolve(resp);
      });
      earlyRequest.onError(err => {
        console.warn('Early video player fetch failed:', err);
        reportError(err, {
          errorId: 'QUICK_FETCH_VIDEO_FAILED'
        });
        fetchPlayer(id, params).then(resolve, reject).catch(reject);
      });
    });
  }
  return fetchPlayer(id, params);
}
export function trackPlayEvent(crmObjectId, sessionId, contactUtk, extra = {}) {
  const query = {
    portalId: PortalIdParser.get()
  };
  const data = Object.assign({}, extra, {
    crmObjectId,
    sessionId,
    contactUtk
  });
  return http.post('media-bridge/public/v1/media/played', {
    data,
    query,
    withCredentials: false
  });
}
export function trackSecondsViewed(crmObjectId, sessionId, contactUtk, extra = {}) {
  const query = {
    portalId: PortalIdParser.get()
  };
  if (extra.endState) {
    query.endState = true;
  }
  delete extra.endState;
  const data = Object.assign({}, extra, {
    crmObjectId,
    sessionId,
    contactUtk
  });
  return http.post('media-bridge/public/v1/media/seconds-viewed', {
    data,
    query,
    withCredentials: false
  });
}
export function trackCompletedPlay(crmObjectId, sessionId, contactUtk, extra = {}) {
  if (!navigator || typeof navigator.sendBeacon !== 'function') {
    return false;
  }
  try {
    const data = new Blob([JSON.stringify(Object.assign({
      crmObjectId,
      sessionId,
      contactUtk
    }, extra))], {
      type: 'text/plain'
    });
    // eslint-disable-next-line compat/compat
    return navigator.sendBeacon(`${getFullUrl('api')}/media-bridge/public/v1/media/completed-play?portalId=${PortalIdParser.get()}`, data);
  } catch (err) {
    console.error('Error tracking final attention span', err);
    return false;
  }
}