export const HUBSPOT_VIDEO_2_PROVIDER = 'HUBSPOT_VIDEO_2';
export const MUX_THUMBNAIL_DOMAIN = 'image.mux.com';
export const DEFAULT_MUX_POSTER_WIDTH = 1200;
export let REQUEST_STATUS_TYPES;
(function (REQUEST_STATUS_TYPES) {
  REQUEST_STATUS_TYPES["UNINITIALIZED"] = "UNINITIALIZED";
  REQUEST_STATUS_TYPES["PENDING"] = "PENDING";
  REQUEST_STATUS_TYPES["SUCCEEDED"] = "SUCCEEDED";
  REQUEST_STATUS_TYPES["FAILED"] = "FAILED";
  REQUEST_STATUS_TYPES["NOT_FOUND"] = "NOT_FOUND";
})(REQUEST_STATUS_TYPES || (REQUEST_STATUS_TYPES = {}));