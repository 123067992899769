import { MetricsDaemon } from './daemon/MetricsDaemon';
import { Counter } from './metric/Counter';
import { Histogram } from './metric/Histogram';
import { SessionCounter } from './metric/SessionCounter';
import { Timer } from './metric/Timer';
const $SessionCounter = Symbol('SessionCounter');
class MetricsFactory {
  constructor(namespace, globalDimensions = {}) {
    this.namespace = namespace;
    this.globalDimensions = globalDimensions;
  }
  namespaceMetric(name) {
    return `${this.namespace}.${name}`;
  }
  counter(name, dimensions = {}) {
    return MetricsDaemon.instance().getMetric(this.namespaceMetric(name), Object.assign({}, this.globalDimensions, dimensions), Counter);
  }

  /**
   * SessionCounters have some nuance that make them challenging to use
   * correctly. For now, we access them via a Symbol to avoid external
   * consumers accessing this API.
   * @internal
   */
  [$SessionCounter](name, dimensions = {}) {
    return MetricsDaemon.instance().getMetric(this.namespaceMetric(name), Object.assign({}, this.globalDimensions, dimensions), SessionCounter);
  }
  histogram(name, dimensions = {}) {
    return MetricsDaemon.instance().getMetric(this.namespaceMetric(name), Object.assign({}, this.globalDimensions, dimensions), Histogram);
  }
  timer(name, dimensions = {}) {
    return MetricsDaemon.instance().getMetric(this.namespaceMetric(name), Object.assign({}, this.globalDimensions, dimensions), Timer);
  }
}
export { MetricsFactory, $SessionCounter };