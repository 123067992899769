import { isIframed, shouldIgnoreUserAgent } from 'video-embed/utils/env';
import { logPageAction } from 'video-embed/utils/logging';
import startApp from './start';
window._hsq = window._hsq || [];
if (isIframed()) {
  document.body.classList.add('player-iframed');
} else {
  document.body.classList.add('player-standalone');
}
if (window.location.search.includes('maxHeight=')) {
  document.body.classList.add('with-max-height');
}
if (shouldIgnoreUserAgent()) {
  document.body.classList.add('player-unsupported');
  logPageAction('ignore unsupported useragent', {
    userAgent: navigator.userAgent
  });
} else {
  window._app = startApp();
}