import { useCallback, useEffect } from 'react';
import Raven from 'raven-js';
import { MessageTypes } from 'video-embed/constants';
import { debugLog } from 'video-embed/utils/debug';
import { isPlayerMessage } from 'video-embed/utils/embed';
import { logPageAction } from 'video-embed/utils/logging';
import { isValidUrl } from 'video-embed/utils/url';
import { PlayerStatus } from '../constants';
const MessageHandler = ({
  setRef,
  embedId,
  parentOrigin,
  video,
  onSetUtk,
  onSetPageMeta,
  onSetCurrentTime,
  onSetPlaybackRate,
  onUpdateDisplayOptions,
  onUpdatePlayerStatus,
  onSubmitOverlay,
  onSkipOverlay,
  onConfirmVisible
}) => {
  const postMessageToParent = useCallback((messageType, payload = {}) => {
    if (!isValidUrl(parentOrigin)) {
      return;
    }
    let messageData = {
      embedId,
      type: messageType,
      payload
    };
    if (video) {
      messageData = Object.assign({}, messageData, {
        videoId: video.videoId,
        videoTitle: video.title,
        isEmbeddable: video.isEmbeddable
      });
    }
    window.parent.postMessage(messageData, parentOrigin);
  }, [embedId, video, parentOrigin]);
  const handlePostMessage = useCallback(e => {
    if (!isPlayerMessage(e)) {
      return;
    }
    const {
      data
    } = e;
    debugLog(`Player "${embedId}" received postMessage`, data);
    Raven.captureBreadcrumb({
      category: 'postMessage',
      message: `Player received message: ${data.type} ${JSON.stringify(data.payload)}`
    });
    let payload = data.payload;
    if (payload && typeof payload === 'string' && payload[0] === '{') {
      try {
        payload = JSON.parse(payload);
      } catch (err) {
        debugLog('Could not parse message JSON payload', err, e);
        logPageAction('parse postMessage payload failed');
      }
    }
    if (data.type === MessageTypes.SET_UTK) {
      const utk = payload ? payload.utk : null;
      onSetUtk(utk);
    } else if (data.type === MessageTypes.SET_PAGE_META) {
      onSetPageMeta(payload);
    } else if (data.type === MessageTypes.SET_CURRENT_TIME) {
      const currentTime = data.currentTime || data.payload;
      if (typeof currentTime === 'number') {
        onSetCurrentTime(currentTime);
      }
    } else if (data.type === MessageTypes.SET_PLAYBACK_RATE) {
      if (typeof data.payload === 'number') {
        onSetPlaybackRate(data.payload);
      }
    } else if (data.type === MessageTypes.UPDATE_DISPLAY_OPTIONS && payload) {
      onUpdateDisplayOptions(payload);
    } else if (data.type === MessageTypes.SET_PLAYER_STATUS && payload) {
      if (Object.values(PlayerStatus).includes(payload.status)) {
        onUpdatePlayerStatus(payload.status);
      }
    } else if (data.type === MessageTypes.CTA_SKIPPED || data.type === MessageTypes.FORM_SKIPPED) {
      onSkipOverlay(payload);
    } else if (data.type === MessageTypes.CTA_CLICKED || data.type === MessageTypes.FORM_SUBMITTED) {
      onSubmitOverlay(payload);
    } else if (data.type === MessageTypes.CONFIRM_VISIBLE) {
      onConfirmVisible();
    }
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  []);
  useEffect(() => {
    debugLog(`Adding postMessage listener for embed ${embedId}`);
    window.addEventListener('message', handlePostMessage);
    postMessageToParent(MessageTypes.PLAYER_LOADED);
    return () => {
      window.removeEventListener('message', handlePostMessage);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (setRef) {
    setRef({
      postMessageToParent
    });
  }
  return null;
};
export default MessageHandler;