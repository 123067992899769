import { UPDATE_PARAMS } from '../actions/ActionTypes';
export default function paramsReducer(state = {}, action) {
  const {
    params
  } = action;
  switch (action.type) {
    case UPDATE_PARAMS:
      return Object.assign({}, state, params);
    default:
      return state;
  }
}