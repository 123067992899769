import enviro from 'enviro';
import PortalIdParser from 'PortalIdParser';
import { parse, stringify } from 'hub-http/helpers/params';
import { na1 as DEFAULT_HUBLET } from 'hubspot-url-utils/hublets';
import { ConversionAssetPositions, ConversionAssetTypes, PlayerTypes } from 'video-embed/types';
import { debugLog } from 'video-embed/utils/debug';
import { deserializeConversionAsset, isValidUrl, parseBoolParam, parseQueryParams } from 'video-embed/utils/url';
const VIDEO_ID_PATH_REGEX = /(v|video)\/(\d+)\/id\/(\d+)/i;
const BOOL_PARAMS = ['autoplay', 'loop', 'muted', 'hiddenControls', 'hidePosterImage'];
function parseConversionAsset(assetStr) {
  const conversionAsset = deserializeConversionAsset(assetStr);
  if (!(conversionAsset && conversionAsset.id && conversionAsset.type && conversionAsset.position)) {
    return null;
  }
  conversionAsset.type = conversionAsset.type.toUpperCase();
  if (!Object.values(ConversionAssetTypes).includes(conversionAsset.type)) {
    console.warn(`Unknown conversionAsset type: ${conversionAsset.type}`);
    return null;
  }
  conversionAsset.position = conversionAsset.position.toUpperCase();
  if (!Object.values(ConversionAssetPositions).includes(conversionAsset.position)) {
    console.warn(`Unknown conversionAsset position: ${conversionAsset.position}`);
    return null;
  }
  if (conversionAsset.position === ConversionAssetPositions.CUSTOM) {
    if (Number(conversionAsset.overlayDelaySeconds) >= 0) {
      conversionAsset.overlayDelaySeconds = Number(conversionAsset.overlayDelaySeconds);
    }
  }
  return conversionAsset;
}
export function parsePlayerUrlParams(path = window.location.pathname, queryString = window.location.search, hash = window.location.hash) {
  const params = parseQueryParams(queryString);
  params.portalId = PortalIdParser.get();
  if (params.id) {
    params.id = parseInt(params.id, 10);
  } else if (path) {
    const match = path.match(VIDEO_ID_PATH_REGEX);
    if (match && match.length === 4) {
      params.id = parseInt(match[3], 10);
    }
  }
  if (hash && hash.startsWith('#hsvid=')) {
    const hashParams = parseQueryParams(hash.replace('#', ''));
    if (hashParams.hsvid) {
      params.embedId = hashParams.hsvid;
    }
    if (hashParams.t && Number(hashParams.t)) {
      params.frameLoadDelay = Date.now() - Number(hashParams.t);
      debugLog(`Player ${params.embedId} loaded ${params.frameLoadDelay}ms after parent page`);
    }
    if (hashParams.conversionAsset) {
      params.conversionAsset = hashParams.conversionAsset;
    }
    if (hashParams.afterPreload) {
      params.afterPreload = parseBoolParam(hashParams.afterPreload);
    }
  }
  if (params.hidden_controls) {
    params.hiddenControls = params.hidden_controls;
    delete params.hidden_controls;
  }
  if (params.autoPlay && !params.autoplay) {
    params.autoplay = params.autoPlay;
    delete params.autoPlay;
  }
  if (params.playbackRate && Number(params.playbackRate) > 0 && Number(params.playbackRate) !== 1) {
    params.playbackRate = Number(params.playbackRate);
  }
  BOOL_PARAMS.forEach(param => {
    if (params[param]) {
      params[param] = parseBoolParam(params[param]);
    }
  });
  if (params.richTextPreview) {
    params.richTextPreview = parseBoolParam(params.richTextPreview);
    params.playerType = PlayerTypes.RICH_TEXT_PREVIEW;
  } else if (params.playerType === PlayerTypes.RICH_TEXT_PREVIEW) {
    params.richTextPreview = true;
  }
  if (params.play_button_color) {
    params.playButtonColor = params.play_button_color;
    delete params.play_button_color;
  }
  if (params.playButtonColor && params.playButtonColor.startsWith('#')) {
    params.playButtonColor = params.playButtonColor.replace('#', '');
  }
  // video widget defaults to white making triangle svg invisible, so ignore
  if (params.playButtonColor === 'ffffff') {
    delete params.playButtonColor;
  }
  if (params.play_button_shape) {
    params.playButtonShape = params.play_button_shape;
    delete params.play_button_shape;
  }
  if (params.conversion_asset) {
    params.conversionAsset = params.conversion_asset;
    delete params.conversion_asset;
  }
  if (params.ca) {
    params.conversionAsset = params.ca;
    delete params.ca;
  }
  if (params.conversionAsset) {
    params.conversionAsset = parseConversionAsset(params.conversionAsset);
    if (!params.conversionAsset) {
      delete params.conversionAsset;
    }
  }
  return params;
}
export function getCtaFallbackImageUrl(guid) {
  const domain = enviro.isQa() ? 'hubspotqa.com' : 'hubspot.com';
  let fallbackImgOrigin = `//no-cache.${domain}`;
  if (enviro.getHublet() !== DEFAULT_HUBLET) {
    fallbackImgOrigin = enviro.isQa() ? '//hubspot-no-cache-eu1-qa.s3.amazonaws.com' : '//hubspot-no-cache-eu1-prod.s3.amazonaws.com';
  }
  return `${fallbackImgOrigin}/cta/default/${PortalIdParser.get()}/${guid}.png`;
}
export function getCtaRedirectUrl(guid) {
  const domain = enviro.isQa() ? 'hubspotqa.com' : 'hubspot.com';
  let redirectOrigin = `//cta-redirect.${domain}`;
  if (enviro.getHublet() !== DEFAULT_HUBLET) {
    redirectOrigin = enviro.isQa() ? '//hubspot-cta-redirect-eu1-qa.s3.amazonaws.com' : '//hubspot-cta-redirect-eu1-prod.s3.amazonaws.com';
  }
  return `${redirectOrigin}/cta/redirect/${PortalIdParser.get()}/${guid}`;
}
export const parseQueryObject = location => {
  if (location.search) {
    try {
      return parse(location.search.substring(1));
    } catch (e) {
      console.warn('Failed to parse query string', e);
    }
  }
  return null;
};
export const parseQueryObjectFromUrl = urlString => {
  if (!urlString) {
    return {};
  }
  try {
    const url = new URL(urlString);
    return url.search ? parseQueryObject(url) : {};
  } catch (e) {
    return {};
  }
};
export const mergeQueryParams = (location, queryParamUpdate = {}) => {
  let queryObj = parseQueryObject(location);
  queryObj = Object.assign({}, queryObj, queryParamUpdate);
  location.search = `?${stringify(queryObj)}`;
  return location;
};
const PARAMS_TO_PRESERVE = ['hs_preview'];

// remove query params to make amplitude analysis easier / more efficient, other than ?hs_preview= required to see content before publish
export function cleanPageUrl(pageUrl) {
  if (!isValidUrl(pageUrl)) {
    return pageUrl;
  }
  const params = parseQueryParams(pageUrl);
  const paramsToPreserve = {};
  PARAMS_TO_PRESERVE.forEach(key => {
    if (params[key]) {
      paramsToPreserve[key] = params[key];
    }
  });
  const urlWithoutQuery = pageUrl.split('?')[0];
  if (Object.keys(paramsToPreserve).length) {
    return `${urlWithoutQuery}?${stringify(paramsToPreserve)}`;
  }
  return urlWithoutQuery;
}