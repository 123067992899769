const font5 = 'fontawesome-5';
const font5_0_10 = 'fontawesome-5.0.10';
const font5_14_0 = 'fontawesome-5.14.0';
const font6_4_2 = 'fontawesome-6.4.2';
export const ICON_SETS = {
  [font5]: font5,
  [font5_0_10]: font5_0_10,
  [font5_14_0]: font5_14_0,
  [font6_4_2]: font6_4_2
};
export const DEFAULT_ICON_SET = ICON_SETS[font5_0_10];