import enviro from 'enviro';
const MUX_ENV_KEYS = {
  na1: {
    qa: 'q9om0msnh4pkbbusrg9i95j8u',
    prod: 'bbh9mqlh0bi8si8rd8kc86qf7'
  },
  eu1: {
    qa: 'cl2ljlrmq4j4ot7ok41ubji48',
    prod: 'dld0a7bdnsnh2eqrj4q756877'
  }
};
export const getPlayerEnv = () => enviro.getShort('video_player');
export const getFormsEmbedEnv = () => enviro.getShort('forms_embed');
export function getMuxEnvKey() {
  const hublet = enviro.getHublet();
  const hubletKeys = MUX_ENV_KEYS[hublet] || MUX_ENV_KEYS.na1;
  return enviro.isQa() ? hubletKeys.qa : hubletKeys.prod;
}
export function isBrowseriOS() {
  return ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform);
}