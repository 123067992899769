import userInfo from 'hub-http/userInfo';
import { INITIALIZE_AUTH } from './ActionTypes';
export const getAuth = () => dispatch => {
  return userInfo().then(({
    user,
    gates,
    portal
  }) => {
    dispatch({
      type: INITIALIZE_AUTH,
      user,
      gates,
      portal
    });
    return {
      user,
      gates,
      portal
    };
  });
};