import { logPageAction } from 'video-embed/utils/logging';
import { isValidUrl } from 'video-embed/utils/url';
import createUsageTracker from './utils/tracker';
import { parsePlayerUrlParams } from './utils/url';
import Application from './App';
export default function startApp() {
  const params = parsePlayerUrlParams();
  if (!params.parentOrigin) {
    logPageAction('parentOrigin param missing');
  } else if (!isValidUrl(params.parentOrigin)) {
    logPageAction('parentOrigin param invalid', {
      parentOrigin: params.parentOrigin
    });
  }
  const tracker = createUsageTracker({
    hostApp: params.hostApp,
    pageDomain: params.parentOrigin,
    conversionAssetType: params.conversionAsset ? params.conversionAsset.type : undefined,
    conversionAssetPosition: params.conversionAsset ? params.conversionAsset.position : undefined,
    overlayPreventSkip: params.conversionAsset ? Boolean(params.conversionAsset.preventSkip) : undefined,
    renderContext: params.renderContext,
    autoplay: Boolean(params.autoplay),
    muted: Boolean(params.muted),
    loop: Boolean(params.loop),
    hiddenControls: Boolean(params.hiddenControls),
    loadDelaySeconds: params.frameLoadDelay ? params.frameLoadDelay / 1000 : 0,
    afterPreload: Boolean(params.afterPreload)
  });
  const app = new Application();
  if (!window.location.pathname.startsWith('/video-preloader/')) {
    app.start(params, tracker);
  }
  return app;
}