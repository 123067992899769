import enviro from 'enviro';
import { getSubDomain, getDomain, getTld, getPathPrefix } from './internal';
export function getFullUrl(subDomainPrefix, overrideConfig) {
  const subDomain = getSubDomain(subDomainPrefix, overrideConfig);
  const domain = getDomain(overrideConfig);
  const tld = getTld(overrideConfig);
  const pathPrefix = getPathPrefix(subDomainPrefix);
  return `https://${subDomain}.${domain}.${tld}${pathPrefix}`;
}
export function getFullUrlWithCurrentSubDomain() {
  const subdomain = enviro.deployed() ? 'app' : 'local';
  return getFullUrl(subdomain);
}