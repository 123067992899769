// borrowed from https://git.hubteam.com/HubSpot/react-rhumb/blob/master/react-rhumb/static/ambient-types/global.d.ts#L4

export function getNewRelic() {
  if (window.newrelic && window.newrelic.addPageAction) {
    return window.newrelic;
  }
  return null;
}

// run `localStorage.NEWRELIC_DEBUG = true` to see actions and errors in console as well
export function logPageAction(eventName, attrs = {}) {
  const newRelic = getNewRelic();
  if (!newRelic) {
    return;
  }
  attrs.eventName = eventName;
  newRelic.addPageAction('hubspot-video-event', attrs);
}
export function logError(error, attrs = {}) {
  const newRelic = getNewRelic();
  if (!newRelic) {
    return;
  }
  attrs.source = 'hubspot-video';
  newRelic.noticeError(error, attrs);
}