/**
 * Flag enum of Design Asset Types.
 */

export const NONE = 0;
export const LAYOUT = 1;
export const TEMPLATE = 2;
export const CUSTOM_WIDGET = 4;
export const GLOBAL_GROUP = 8;
export const GLOBAL_WIDGET = 16;
const AssetTypes = {
  NONE,
  LAYOUT,
  TEMPLATE,
  CUSTOM_WIDGET,
  GLOBAL_GROUP,
  GLOBAL_WIDGET
};
export default Object.freeze(AssetTypes);