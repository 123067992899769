'use es6';

import { query, header } from './core';
import { parseUrl } from '../helpers/url';
import * as params from '../helpers/params';
const formatVersion = version => {
  if (version == null) {
    return 'unknown';
  }
  if (version === 'static') {
    return 'dev';
  }
  return version.replace('static-', '');
};
export const withStaticAppInfo = options => options.appInfo ? header('X-HubSpot-Static-App-Info', `${options.appInfo.name}@${formatVersion(options.appInfo.version)}`)(options) : options;
export const ensureStaticAppInfo = options => {
  if (!options.appInfo) return options;
  const queries = params.parse(parseUrl(options.url).query);
  return query({
    hs_static_app: queries.hs_static_app || options.appInfo.name,
    hs_static_app_version: queries.hs_static_app_version || `${formatVersion(options.appInfo.version)}`
  })(options);
};