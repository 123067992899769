/* hs-eslint ignored failing-rules */
/* eslint-disable @typescript-eslint/no-misused-promises */

import http from 'hub-http/clients/apiClient';
const URL = 'cos-rendering/v1/internal/preview-domain';
import getEarlyRequesterResultPromise from './getEarlyRequesterResultPromise';
export const fetchPreviewDomain = () => {
  return getEarlyRequesterResultPromise('early-preview-domain', {
    removeRequestOnSuccess: false
  }) || http.get(URL);
};