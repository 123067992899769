import { initErrorMetrics } from './daemon/initErrorMetrics';
import { runMetricsDaemon } from './daemon/runMetricsDaemon';
import { MetricsFactory } from './MetricsFactory';
function createMetricsFactory(namespace, options = {}) {
  if (!namespace) {
    throw new Error('[metrics-js] A namespace is required for a Metrics factory.');
  }
  runMetricsDaemon();
  initErrorMetrics();
  const dimensions = options.dimensions || {};
  if (options.library) {
    dimensions.fe_library = options.library;
  }
  return new MetricsFactory(namespace, dimensions);
}
export { createMetricsFactory };