import * as cookieHelper from '../client/cookieHelper';
import anonymousStorage from '../containers/anonymousStorage';
import tempStorage from '../containers/tempStorage';
import { getAndRefreshHamplitudeProperties } from '../metaProperties';
import { anonymousUtkKey, hstcKey, utkKey } from '../storageKeys';
import { lWindow } from './helpers';

// Retrieves the HubSpot HSTC from the HSTC Cookie
export function getHstc() {
  return cookieHelper.get(hstcKey) || null;
}

// Retrieves the HubSpot UTK from the UTK Cookie
// if available, otheriwse retrieves the HSTC
// and if available, extracts the UTK from the HSTC
export function getUtk() {
  const hstc = getHstc();
  const utk = cookieHelper.get(utkKey);
  return utk || hstc && hstc.split('.')[1] || null;
}

// Retrieves the Anonymous HubSpot UTK from HubSpot's 53.js
// whenever available, and then "normalizes" it into a HSTC format
// NOTE: This method will only return something within HubSpot.com, Signup
// and other microp-apps that have 53.js enabled
export function getAnonymousHstc() {
  return anonymousUtkKey in lWindow ? `.${String(lWindow[anonymousUtkKey])}` : null;
}

// Retrieves the current running tracker DeviceID based on the correct
// storage that the tracker should be using based on if it's currently
// running on anonymous mode or not. Note that this is a best-effort method
// i.e.: If the current tracker running on the page is the "public" tracker
// despite there not being an UTK, then it will erroneously retrieve a wrong deviceId
// which is not used by the currently running tracker; For that we allow users to override
// the desired storage to be used.
export function getDeviceId(storage = getUtk() ? tempStorage : anonymousStorage) {
  const {
    device_id
  } = getAndRefreshHamplitudeProperties(storage.getItem, storage.setItem, {
    currentTime: Date.now()
  });
  return device_id;
}

// Retrieves the current running tracker SessionID based on the correct
// storage that the tracker should be using based on if it's currently
// running on anonymous mode or not. Note that this is a best-effort method
// i.e.: If the current tracker running on the page is the "public" tracker
// despite there not being an UTK, then it will erroneously retrieve a wrong SessionID
// which is not used by the currently running tracker; For that we allow users to override
// the desired storage to be used.
export function getSessionId(storage = getUtk() ? tempStorage : anonymousStorage) {
  const {
    session_id
  } = getAndRefreshHamplitudeProperties(storage.getItem, storage.setItem, {
    currentTime: Date.now()
  });
  return session_id;
}