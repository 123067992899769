import { CONFIRMED_VISIBLE, FETCH_VIDEO_FAILED, FETCH_VIDEO_SUCCESS, SET_PLAYER_ERROR } from '../actions/ActionTypes';
const InitialVideoState = {
  video: null,
  videoFetchError: null,
  appError: null,
  gates: null,
  domainInfo: null,
  confirmedVisible: false
};
export default function videoReducer(state = InitialVideoState, action) {
  switch (action.type) {
    case FETCH_VIDEO_SUCCESS:
      return Object.assign({}, state, {
        video: action.video,
        gates: action.gates,
        domainInfo: action.parentDomain
      });
    case FETCH_VIDEO_FAILED:
      return Object.assign({}, state, {
        videoFetchError: action.videoFetchError
      });
    case SET_PLAYER_ERROR:
      return Object.assign({}, state, {
        appError: action.appError
      });
    case CONFIRMED_VISIBLE:
      return Object.assign({}, state, {
        confirmedVisible: true
      });
    default:
      return state;
  }
}