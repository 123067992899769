class Metric {
  constructor(name, dimensions) {
    this.name = name;
    this.dimensions = dimensions;
  }
  getDimensions() {
    return this.dimensions;
  }
  getName() {
    return this.name;
  }
  getSeries(qualifier) {
    return [this.name, qualifier].join('.');
  }
  toString() {
    return JSON.stringify({
      name: this.getName(),
      dimensions: this.getDimensions()
    }, null, 2);
  }
}
export { Metric };