import { REQUEST_STATUS_TYPES } from 'video-data-lib/constants';
import { FETCH_CAPTION_URLS_ATTEMPTED, FETCH_CAPTION_URLS_FAILED, FETCH_CAPTION_URLS_SUCCEEDED } from '../actions/ActionTypes';
const captions = (state = {
  requestStatus: REQUEST_STATUS_TYPES.UNINITIALIZED
}, action) => {
  switch (action.type) {
    case FETCH_CAPTION_URLS_ATTEMPTED:
      return {
        requestStatus: REQUEST_STATUS_TYPES.PENDING
      };
    case FETCH_CAPTION_URLS_FAILED:
      return {
        requestStatus: REQUEST_STATUS_TYPES.FAILED
      };
    case FETCH_CAPTION_URLS_SUCCEEDED:
      return {
        requestStatus: REQUEST_STATUS_TYPES.SUCCEEDED,
        captions: action.captions
      };
    default:
      return state;
  }
};
export default captions;