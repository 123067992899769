import Routes from '../Routes';
export function isNullOrUndefined(value) {
  return value === null || value === undefined;
}
export function isObject(item) {
  return item && typeof item === 'object' && !Array.isArray(item);
}
export const isEmptyObject = obj => {
  return obj != null && Object.keys(obj).length === 0 && obj.constructor === Object;
};
export const getDeepClone = (object, onlyTruthy = false) => {
  if (isNullOrUndefined(object) || typeof object !== 'object') {
    return object;
  }
  let result;
  if (Array.isArray(object)) {
    result = object.map(item => {
      return getDeepClone(item, onlyTruthy);
    });
  } else {
    result = Object.assign({}, object);
    Object.keys(result).forEach(key => {
      const value = getDeepClone(result[key], onlyTruthy);
      if (onlyTruthy && isNullOrUndefined(value)) {
        delete result[key];
      } else {
        result[key] = value;
      }
    });
  }
  return result;
};
export function isArrayOrObject(obj) {
  return Array.isArray(obj) || typeof obj === 'object';
}
export function setDeepValue(originalObj, valueKey, value) {
  const key = valueKey[0];
  let obj;
  if (!originalObj || !isArrayOrObject(originalObj)) {
    obj = typeof key === 'number' ? [] : {};
  } else {
    obj = JSON.parse(JSON.stringify(originalObj));
  }
  if (!Array.isArray(valueKey) || !valueKey || !valueKey.length) {
    return obj;
  }
  if (valueKey.length === 1) {
    obj[key] = value;
  } else {
    obj[key] = setDeepValue(obj[key], valueKey.slice(1), value);
  }
  return obj;
}
export function getDeepValue(searchObject, valueKey) {
  if (!Array.isArray(valueKey) || isNullOrUndefined(searchObject) || typeof searchObject !== 'object') {
    return undefined;
  }
  let i = 0;
  let current = JSON.parse(JSON.stringify(searchObject));
  while (i < valueKey.length) {
    if (isNullOrUndefined(current) || typeof current !== 'object') {
      return undefined;
    }
    current = current[valueKey[i]];
    i = i + 1;
  }
  return current;
}
export function removeDeepValue(searchObject, valueKey) {
  if (!Array.isArray(valueKey)) return undefined;
  const obj = JSON.parse(JSON.stringify(searchObject));
  let currentObj = obj;
  valueKey.forEach((key, index) => {
    if (index === valueKey.length - 1) {
      delete currentObj[key];
      return;
    } else if (typeof currentObj[key] === 'object') {
      currentObj = currentObj[key];
    } else {
      return;
    }
  });
  return obj;
}

// Deep merge function that merges arrays
export function mergeDeep2(target, source) {
  const bothObjects = isObject(target) && isObject(source);
  const bothArrays = Array.isArray(target) && Array.isArray(source);
  if (!bothObjects && !bothArrays) {
    return source;
  }
  if (Array.isArray(target)) {
    const output = [...target];
    source.forEach((sourceValue, index) => {
      const targetValue = target[index];
      output[index] = mergeDeep2(targetValue, sourceValue);
    });
    return output;
  }
  const output = Object.assign({}, target);
  Object.keys(source).forEach(key => {
    const targetValue = target[key];
    const sourceValue = source[key];
    output[key] = mergeDeep2(targetValue, sourceValue);
  });
  return output;
}

// Jacked this from https://stackoverflow.com/a/37164538
export function mergeDeep(target, source) {
  if (Routes.isUngated('Cms:FixDefaultValuesInGroups')) {
    return mergeDeep2(target, source);
  }
  const output = Object.assign({}, target);
  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach(key => {
      if (isObject(source[key])) {
        if (!(key in target)) {
          Object.assign(output, {
            [key]: source[key]
          });
        } else {
          output[key] = mergeDeep(target[key], source[key]);
        }
      } else {
        Object.assign(output, {
          [key]: source[key]
        });
      }
    });
  }
  return output;
}