import { combineReducers } from 'redux';
import auth from './auth';
import params from './params';
import video from './video';
import captions from './captions';
export default combineReducers({
  auth,
  params,
  captions,
  video
});